export const getCookieValue = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(nameEQ)) {
      return trimmedCookie.substring(nameEQ.length, trimmedCookie.length);
    }
  }
  return null;
};

export function getAllCookies(): Record<string, string> {
  const cookies: Record<string, string> = {};
  const decodedCookies = decodeURIComponent(document.cookie);
  if (!decodedCookies) {
    return cookies;
  }

  const cookiesArray = decodedCookies.split('; ');
  for (const cookie of cookiesArray) {
    const [name, value] = cookie.split('=');
    cookies[name] = value;
  }

  return cookies;
}

export interface SetCookieOptions {
  expires?: Date;
  path?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
}

export function setCookie(name: string, value: string, options: SetCookieOptions = {}): void {
  const { expires, path = '/', secure = true, sameSite = 'none' } = options;

  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (expires) {
    cookieString += `; expires=${expires.toUTCString()}`;
  }

  cookieString += `; path=${path}`;
  cookieString += `; sameSite=${sameSite}`;
  cookieString += secure ? `; secure` : '';

  document.cookie = cookieString;
}

export function deleteCookie(name: string, path = '/'): void {
  setCookie(name, '', { path, expires: new Date(0) });
}
